import React from 'react'
import Layout from './layout.js'

import '../styles/styles.css';
import '../styles/page.css';

const MarkdownPageLayout = (props) => {
  return (
    <Layout>
      <div className="page-container">
      {props.children}
      </div>
    </Layout>
  )
}

export default MarkdownPageLayout