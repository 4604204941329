import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/markdown-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div>
      <h1 {...{
        "id": "the-gerrymandering-project-does-nonpartisan-analysis-to-understand-and-eliminate-partisan-gerrymandering-at-a-state-by-state-level"
      }}>{`The Gerrymandering Project does `}<strong parentName="h1">{`nonpartisan analysis`}</strong>{` to understand and eliminate partisan gerrymandering at a state-by-state level.`}</h1>
      <p>{`The Supreme Court `}<a parentName="p" {...{
          "href": "http://election.princeton.edu/2018/06/18/decisions-and-punts-on-partisan-gerrymandering-cases-developing/"
        }}>{`acknowledged`}</a>{` the validity of `}<a parentName="p" {...{
          "href": "http://election.princeton.edu/wp-content/uploads/2017/08/16-1161bsacHeatherKGerken-et-al.pdf"
        }}>{`our math`}</a>{` but declined to act. Looking ahead, the strongest route to reform is at a `}<a parentName="p" {...{
          "href": "http://prospect.org/article/states-are-now-best-route-gerrymandering-reform"
        }}>{`state-by-state`}</a>{` level — a federalist approach.`}</p>
      <p>{`Our interdisciplinary team aims to give activists and legislators the tools they need to detect offenses and craft bulletproof, bipartisan reform. Our analysis is `}<a parentName="p" {...{
          "href": "https://scholar.google.com/scholar?hl=en&as_sdt=0%2C33&q=sam+wang+gerrymandering&btnG="
        }}>{`published widely`}</a>{`, and our work is used by legislators and reformers of all communities, without regard to partisan affiliation.`}</p>
      <p><strong parentName="p">{`We translate math into law, and law into math.`}</strong></p>
      <p>{`Election laws and the Constitution are silent on critical issues of representation and districting. By understanding the real-world impacts of the law as it stands, we can help patch holes and fix bugs. Despite a recalcitrant Supreme Court, paths to reform remain open in federal courts, state courts, state ballot initiatives, and state legislation. In `}<a parentName="p" {...{
          "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3335622"
        }}>{`this paper`}</a>{`, we describe an interdisciplinary approach where mapmaking, statistics, and law can work together to fill a current legal void.`}</p>
      <p>{`We have developed a Redistricting Report Card that converts metrics of partisan and racial performance data to a grade, using quantitative rubrics that are the same from state to state. These metrics use our own statistical analysis standards, computational simulations, and data from the U.S. Census and VEST. We gratefully acknowledge the efforts of the `}<a parentName="p" {...{
          "href": "https://election.lab.ufl.edu/precinct-data/"
        }}>{`Voting and Election Science Team (VEST)`}</a>{` in gathering precinct-level data of election results across the nation.`}</p>
      <p><strong parentName="p">{`We provide rigorous standards to constrain bad acts.`}</strong></p>
      <p>{`We develop and use mathematical tests that rigorously diagnose unequal opportunity and unfair outcomes in district maps. For instance, we lay out three statistical tests for gerrymandering in the `}<a parentName="p" {...{
          "href": "https://www.stanfordlawreview.org/print/article/three-tests-for-practical-evaluation-of-partisan-gerrymandering/"
        }}>{`Stanford Law Review`}</a>{`, the results of which can be seen on the tests page. They are applicable in claims against individual districts and in claims against statewide districting schemes. These claims can be brought in federal and state courts under different legal provisions, not all of which are subject to review at the U.S. Supreme Court. We are also developing methods for determining whether individuals’ votes are diluted in violation of Equal Protection.`}</p>
      <p><strong parentName="p">{`We help activists and lawmakers.`}</strong></p>
      <p>{`The Gerrymandering Project supports state- and federal-level reform efforts to eliminate partisan gerrymandering. We identify opportunities and loopholes in existing law that shape how district maps are drawn. We can help analyze and craft reform language to help activists translate their ideals into practical solutions. We have produced projects and reports for reformers in Virginia, Michigan, and Ohio, with the goal of advancing redistricting reform in those states. In December 2018, we also drew attention to weaknesses in a redistricting reform bill proposed by New Jersey lawmakers and testified about our findings in committee hearings.`}</p>
    </div>
    <div>
      <h2 {...{
        "id": "projects"
      }}>{`Projects`}</h2>
      <h3 {...{
        "id": "state-reports"
      }}>{`State Reports`}</h3>
      <p>{`We prepare reports that include demonstration maps, as well as statistical and legal analyses to help good-government groups craft lasting reform strategies.`}</p>
      <p><a parentName="p" {...{
          "href": "https://static1.squarespace.com/static/63484e2f3f34101ac3c7e4a1/t/66328f94ef794f0717727a52/1714589592377/EIL102_Ohio-redistricting-reform-guide_Edit-Dec2023B.pdf"
        }}>{`A Citizen’s Guide to Redistricting in Ohio`}</a>{` `}</p>
      <p><a parentName="p" {...{
          "href": "https://gerrymander.princeton.edu/assets/docs/Princeton_MI_report.pdf"
        }}>{`A Commissioner’s Guide to Redistricting in Michigan`}</a></p>
      <h3 {...{
        "id": "improved-communication"
      }}>{`Improved Communication`}</h3>
      <p>{`We will show statisticians how to advance reform, activists how to write foolproof laws, and lawyers how to create arguments backed by mathematical rigor.`}</p>
      <h3 {...{
        "id": "openprecincts"
      }}>{`OpenPrecincts`}</h3>
      <p>{`OpenPrecincts was our early effort to gather and clean redistricting data. At the time, there was no central repository of precinct-level geographic data from across the country available to the public. Since then, major nationwide efforts have made redistricting data widely available for public use. We’re grateful for the `}<a parentName="p" {...{
          "href": "https://www.google.com/url?q=https://dataverse.harvard.edu/dataverse/electionscience&sa=D&source=docs&ust=1717437893747966&usg=AOvVaw0K47_HzkwSVycI9BzTrxB4"
        }}>{`Voting and Election Science Team`}</a>{`, led by Michael McDonald, the `}<a parentName="p" {...{
          "href": "https://redistrictingdatahub.org/"
        }}>{`Redistricting Data Hub`}</a>{`, and `}<a parentName="p" {...{
          "href": "https://davesredistricting.org/maps#home"
        }}>{`Dave’s Redistricting App`}</a>{`.  `}</p>
    </div>
    <h2 {...{
      "id": "partners"
    }}>{`Partners`}</h2>
    <p>{`Our current partner is Educational Ventures. We are grateful for the support of Schmidt Futures, the Marilyn J. Simons Foundation, RepresentUs, the Joyce Foundation, the Five Together Foundation, the Thornberg Foundation, the Fund for New Jersey, and other private donations. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      